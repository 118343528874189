<template>
  <div>
    <grading-round-form></grading-round-form>
  </div>
</template>
<script>
import gradingRoundForm from './components/gradingRoundForm.vue'
export default {
  name:'createRoundSystem',
  components:{gradingRoundForm}
}
</script>
